import React from "react"
import Layout from "../../components/h/layout"

const HalloweenEFPage = () => (
  <Layout>
    <h2>You hid here with Funyuns, it was pretty neat.</h2>
    <h2>This is where you go to get food for your brothers to eat.</h2>
  </Layout>
)

export default HalloweenEFPage
